<template>
  <div class="machine-working-hour theme-origin">

    <div class="calendar__filter">
      <div class="filter__item filter__period">
        <dayPicker 
          class="picker day-picker"
          :key="'filter-period'"
          :preset="calendar.start + ''"
          :presetIcon="['append', false, false]"
          :title="'날짜선택'"
          :fieldHeight="33"
          :clickPick="true"
          :border="true"
          :type="calendar.types[calendar.type] == 'month' ? 'month' : 'date'"
          @picked="getDate"
        ></dayPicker>
        <div class="calendar-type" :class="{ 'mac': is_mac }">
          <v-btn-toggle 
            v-model="calendar.type" 
            mandatory tile
          >
            <v-btn class="day">일</v-btn>
            <v-btn class="week">주</v-btn>
            <v-btn class="month">월</v-btn>
          </v-btn-toggle>
        </div>
      </div>

      <div class="filter__item filter__date">
        <v-btn 
          class="prev"
          fab small text
          @click="setCalPrev"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h3 class="date">
          {{ calendar.types[calendar.type] == 'month' ? setDate2Month(calendar.start): calendar.start }}
        </h3>
        <v-btn 
          class="next"
          fab small text
          @click="setCalNext"
        >
          <v-icon size="32">mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <div class="filter__item filter__machine">
        <select 
          name="calendar-machine" 
          class="calendar-machine"
          v-model="mid.selected"
        >
          <option value="%" class="all">전체</option>
          <option
            class="mid"
            v-for="m in mid.data"
            :key="m.id"
            :value="m.name +'.'+ m.id"
          >{{ m.name }}</option>
        </select>

        <!-- 스케줄 등록 / 수정 -->
        <v-dialog 
          content-class="add-schedule__popup" 
          v-model="schedule.open"
          @click:outside="cancleScheduleAdd"
          dark
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              v-bind="attrs"
              v-on="on"
              class="add-schedule filter-fn-btn"
              :class="{ 'mac': is_mac }"
              @click="showScheduleForm"
              dark tile depressed
            >스케줄 등록</v-btn>
          </template>
          <v-card class="inner">
            <p class="dialog-title">
              스케줄
              <template v-if="schedule.mode == 'create'">등록</template>
              <template v-else-if="schedule.mode == 'modify'">수정</template>
            </p>
            <div class="dialog__content" :class="{ 'mac': is_mac }">
              <label v-if="schedule.mode == 'create' " class="dialog__form-label">
                <span class="label-text">기 계 명</span>
                <select 
                  name="schedule-mid" 
                  class="dialog__form-select schedule-mid" 
                  v-model="schedule.form.mkey"
                >
                  <option value="" default selected disabled>기계명</option>
                  <option
                    class="label-select schedule-mid__i"
                    v-for="m in mid.data"
                    :key="m.id"
                    :value="m.id" 
                  >{{ m.name }}</option>
                </select>
              </label>
              <label class="dialog__form-label">
                <span class="label-text">조 업 명</span>
                <v-text-field 
                  class="label-field name-field rounded-0" 
                  v-model="schedule.form.name"
                  height="35"
                  label="조업명"
                  hide-details solo flat dense light
                ></v-text-field>
              </label>
              <label class="dialog__form-label">
                <span class="label-text">등 록 일</span>
                <dayPicker 
                  class="label-field"
                  :key="'schedule-added-date'" 
                  :preset="schedule.date"
                  :fieldHeight="35"
                  :clickPick="true"
                  :offsetX="true"
                  :offsetY="false"
                  @picked="setScheduleDate"
                ></dayPicker>
              </label>
              <label class="dialog__form-label">
                <span class="label-text">시 작 시 간</span>
                <v-menu 
                  content-class="sche-menu schedule-start-time" 
                  v-model="schedule.start.open"
                  :close-on-content-click="false"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                      class="label-field rounded-0"
                      v-bind="attrs"
                      v-on="on"
                      v-model="schedule.start.time"
                      label="시간 선택"
                      height="35"
                      hide-details readonly solo flat dense light
                    >
                      <template v-slot:prepend>
                        <v-icon size="18">$miniClock</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-card class="inner">
                    <v-time-picker 
                      ref="sche-timepicker-start"
                      v-model="schedule.start.time"
                      :max="schedule.end.time"
                      color="#556b84"
                      format="24hr"
                      @change="(v) => closeTimePicker(v, 'start', )"
                    ></v-time-picker>
                  </v-card>
                </v-menu>
              </label>
              <label class="dialog__form-label">
                <span class="label-text">종 료 시 간</span>
                <v-menu 
                  content-class="sche-menu schedule-end-time" 
                  v-model="schedule.end.open"
                  :close-on-content-click="false"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                      class="label-field rounded-0"
                      v-bind="attrs"
                      v-on="on"
                      v-model="schedule.end.time"
                      height="35"
                      label="시간 선택"
                      hide-details readonly solo flat dense light
                    >
                      <template v-slot:prepend>
                        <v-icon size="18">$miniClock</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-card class="inner">
                    <v-time-picker 
                      ref="sche-timepicker-end"
                      v-model="schedule.end.time"
                      :min="schedule.start.time"
                      color="#556b84"
                      format="24hr"
                      @change="(v) => closeTimePicker(v, 'end')"
                    ></v-time-picker>
                  </v-card>
                </v-menu>
              </label>
            </div>
            <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac }">
              <v-btn 
                class="action-btn add-schedule-cancle"
                @click="addSchedule"
                depressed light
              >{{ schedule.mode == 'modify' ? '수정' : '확인' }}</v-btn>
              <v-btn 
                class="action-btn add-schedule-complete"
                @click="cancleScheduleAdd"
                depressed light
              >취소</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- 스케줄 등록 / 수정 끝 -->
      </div>
    </div>
  
    <div class="calendar-area">
      <v-sheet :height="calendar.height">
      <!-- <v-sheet :height="calendar.type == 2 ? 800 : 'auto'"> -->
        <v-calendar 
          ref="calendar"
          class="calendar"
          :class="{ 'calendar--day': calendar.type == 0, }"
          v-model="calendar.start"
          @change="setRange"
          :start="calendar.start"
          :type="calendar.types[calendar.type]"
          :weekday-format="getDayNames"
          :show-month-on-first="false"
          :event-color="getEventColor"
          :events="events"
          :event-height="25"
          :event-margin-bottom="5"
          :event-overlap-mode="calendar.types[calendar.type] == 'category' && mid.selected != '%' ? 'column' : 'stack'"
          :categories="mid.selected == '%' ? calendar.mids : mid.mid"
          @click:event="showEventDetail"
          @click:more="showEventMore"
          category-show-all
          event-more-text="{0} 더보기 &blacktriangledown;"
        >
        </v-calendar>
      </v-sheet>
      <v-menu
        content-class="calendar__tooltip elevation-0 rounded-0"
        v-model="tooltip.open"
        :activator="tooltip.element"
        :close-on-content-click="false"
        :close-on-click="true"
        right offset-x dark
      >
        <v-card class="inner elevation-0 rounded-0">
          <v-card-actions class="tooltip__actions">
            <v-spacer></v-spacer>
            <v-btn class="action-btn tooltip__delete" fab text x-small @click="confirmDeleteEvent">
              <v-icon size="14">$deleteIcon</v-icon>
            </v-btn>
            <v-btn class="action-btn tooltip__modify" fab text x-small @click="openModifySchedule">
              <v-icon size="14">$modify</v-icon>
            </v-btn>
            <v-btn class="action-btn tooltip__close" fab text x-small @click="closeEventDetail">
              <v-icon size="14">$close</v-icon>
            </v-btn>
          </v-card-actions>
          <div class="tooltip__content">
            <p class="content-title tooltip-name">{{ tooltip.data.name }}</p><br>
            <p class="content-title tooltip-mid">{{ tooltip.data.category }}</p>
            <p class="tooltip-time">
              <template v-if="tooltip.all_day">
                종일
              </template>
              <template v-else>
                <label class="tooltip-label">
                  <span class="label-text">시 작</span>
                  {{ tooltip.data.start.replace(' ', ' / ') }}
                </label>
                <label class="tooltip-label">
                  <span class="label-text">종 료</span>
                  {{ tooltip.data.end.replace(' ', ' / ') }}
                </label>
              </template>
            </p>
          </div>
        </v-card>
      </v-menu>
    </div>

    <!-- 삭제 확인 팝업 -->
    <v-dialog 
      content-class="delete-confirm"
      v-model="tooltip.confirm"
      @click="confirmDeleteEvent"
      dark
    >
      <v-card class="inner">
        <div class="dialog__content">
          <p class="dialog__confirm-text">
            <strong>{{ tooltip.data.name }}</strong>(을)를<br>
            정말 삭제하시겠습니까?
          </p>
        </div>
        <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac }">
          <v-btn 
            class="action-btn cancle-delete-schedule"
            @click="tooltip.confirm = false"
            depressed light
          >취소</v-btn>
          <v-btn 
            class="action-btn delete-schedule"
            @click="deleteEvent"
            depressed light
          >삭제</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayPicker from '../components/dayPicker.vue';
import moment from 'moment';

export default {
  components: {
    dayPicker, moment
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      schedule: {
        open: false,
        mode: 'create',
        mkey: '',
        name: '',
        date: moment().format('YYYY-MM-DD'),
        start: {
          open: false,
          time: '',
        },
        end: {
          open: false,
          time: '',
        },
        form: {
          start: '',
          end: '',
          mkey: '',
          name:'',
        }
      },
      mid: {
        data: [],
        selected: '%',
        mid:'Puma280',
      },
      calendar: {
        height: 800,
        type: 2,
        types: ['category', 'week', 'month'],
        colors: ['#d2c3c9', '#bcd4df', '#bdc3c4', '#c9beb8', '#b3c5ad', '#9c8596', '#8caab4',],
        mids: ['Puma280',],
        start: new Date(),
        date: {
          // selected: new Date(),
          start: '',
          end: '',
        }
      },
      tooltip: {
        open: false,
        element: undefined,
        all_day: undefined,
        data: {
          category: '',
          name: '',
          start: '',
          end: '',
          color: '',
        },
        confirm: false,
      },
      events: [
        {
          'name': '오전1',
          start: '2021-09-28 08:00:00',
          end: '2021-09-28 12:32:24',
          color: '#d2c3c9',
          category: 'Puma210',
        },
      ],
    };
  },
  computed: {
    is_mac() {
      return this.$is_mac;
    },
  },
  watch: {
    'mid.selected':function(v){
      const sche = this.schedule;
      this.mid.mid = v.split('.')[0];
      var mkey = v.length > 1 ?  v.split('.')[1] : v;
      this.getList(sche.start.date, sche.end.date, mkey);
    },
    'calendar.start': function(v){
      this.tooltip.open = false;
    },
    "calendar.type": function(newV, oldV) {
      this.tooltip.open = false;
    },
  },
  methods: {
    getContentHeight() {
      const hei = window.innerHeight;
      const filter = document.querySelector('.calendar__filter');
      const except = {
        header: 70,
        filter: parseInt(getComputedStyle(filter).height),
        padding: 45,
      };
      this.calendar.height = hei - except.header - except.filter - except.padding - 5;
    },
    getEventColor(event){
      var index = this.calendar.mids.indexOf(event.category,0);
      var color = index % this.calendar.colors.length;
      return this.calendar.colors[color]
    },
    getDayNames(d) {
      const day = ['일', '월', '화', '수', '목', '금', '토', ];
      return day[d.weekday];
    },
    selectMid(){
      this.$http.APS.get('/idle/key').then(r=>{
        this.mid.data=r.data;
        this.calendar.mids=[];
        for(let i = 0; i < r.data.length; i++){
          this.calendar.mids.push(r.data[i].name);
        }
      });
    },
    showScheduleForm(){
      this.schedule.mode='create';
      this.cardshow=true;
    },
    setRange({start,end}){
      console.log(start.date,end.date);
      const cal = this.calendar;
      cal.date.start = start.date;
      cal.date.end = end.date;
      var mkey;
      mkey = this.mid.selected.length > 1 ?  this.mid.selected.split('.')[1] :  this.mid.selected ;
      this.getList(cal.date.start, cal.date.end, mkey);
    },
    setScheduleDate(val){
      this.schedule.date = val;
    },
    closeTimePicker(val, type) {
      this.schedule[type].open = false;
      this.$refs['sche-timepicker-' + type].selectingHour = true;
    },
    getDate(val) {
      this.calendar.start = val;
    },
    setCalNext() {
      this.$refs.calendar.next();
    },
    setCalPrev() {
      this.$refs.calendar.prev();
    },
    setDate2Month(val) {
      return String(val).substr(0, 7);
    },
    resetScheduleForm(){
      const sche = this.schedule;
      sche.mkey = '';
      sche.name = '';
      sche.date = this.today;
      sche.end.time = '';
      sche.start.time = '';
    },
    async addSchedule() {
      const sche = this.schedule;
      const cal = this.calendar;

      sche.form.start = sche.date + ' ' + sche.start.time;
      sche.form.end = sche.date + ' ' + sche.end.time;
      sche.form.id = this.tooltip.data.id;    
      if(sche.mode == 'create'){
        await this.$http.APS.post('/schedule/in', sche.form);
        await this.getList(cal.date.start, cal.date.end, this.mid.selected);
      }else if(sche.mode == 'modify'){
        await this.$http.APS.patch('/schedule/', sche.form);
        await this.getList(cal.date.start, cal.date.end, this.mid.selected);
        this.closeEventDetail();
      }
      this.resetScheduleForm();
      sche.open = false;
    },
    cancleScheduleAdd(){
      this.resetScheduleForm();
      this.schedule.open = false;
    },
    showEventDetail({nativeEvent, event}) {
      const tooltip = this.tooltip;
      tooltip.data = event;
      const open = () => {
        tooltip.data = event;
        tooltip.element = nativeEvent.target;
        requestAnimationFrame( () => requestAnimationFrame( () => tooltip.open = true));
      }
      event.start.indexOf(':') == -1 ? tooltip.all_day = true : tooltip.all_day = false;
      if( tooltip.open ) {
        tooltip.open = false;
        requestAnimationFrame( () => requestAnimationFrame( () => open() ));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    closeEventDetail() {
      const tooltip = this.tooltip;
      tooltip.open = false;
      tooltip.element = undefined;
    },
    showEventMore(val) {
      const cal = this.calendar;
      cal.type = 'category';
      cal.start = val.date;
    },
    confirmDeleteEvent() {
      const tooltip = this.tooltip;
      tooltip.confirm = true;
    },
    async deleteEvent() {
      const tooltip = this.tooltip;
      const cal = this.calendar;
      await this.$http.APS.delete('/schedule/' + tooltip.data.id);
      await this.getList(cal.date.start, cal.date.end, this.mid.selected);
      tooltip.confirm = false;
      this.closeEventDetail();
    },
    openModifySchedule() {
      const sche = this.schedule;
      const tooltip = this.tooltip;
      sche.open = true;
      sche.mode = 'modify';
      sche.form.mkey = tooltip.data.mid;
      sche.form.name = tooltip.data.name.split('(')[0];
      sche.date = tooltip.data.start.substr(0, 10);
      sche.start.time = tooltip.data.start.substr(11);
      sche.end.time = tooltip.data.end.substr(11);
    },
    getList(start,end,mid){
      const cal = this.calendar;
      console.log(cal);
      var map = { start: cal.date.start, end: cal.date.end ,mid: mid,type:cal.type};
      this.$http.APS.post('/schedule/',map).then(r=>{
        for(let i = 0 ; i < r.data.length ; i++){
          r.data[i].name = r.data[i].name + '('+ r.data[i].mname+')'; 
          r.data[i].start = this.dateFormatter(r.data[i].start);
          r.data[i].end = this.dateFormatter(r.data[i].end);
          r.data[i].category = r.data[i].mname;
        }
        this.events = r.data;
      })
    },
    dateFormatter(v){
      v = v.substr(0,19).replace('T',' ');
      return v;
    },  
  },
  mounted() {
    this.calendar.start = moment().format('YYYY-MM-DD');
    this.selectMid();

    this.getContentHeight();
  }
}
</script>

<style scoped lang="scss">
  @import '~@/assets/css/global.scss';

  .machine-working-hour {
    @extend .flex_column;
    @extend .common-calendar;

    .calendar__filter {
      @extend .common-filter;

      @include relative-web2-1 {
        flex-wrap: wrap;

        .fitler__period {
          order: 0;
        }
        .filter__date {
          justify-content: center;
          order: 2;
          width: 100%;
          margin-top: 24px;;
          padding: 0;
          position: static;
          transform: unset;
        }
        .filter__machine {
          order: 1;
        }
      }
      padding: 42px 45px 24px;
    }

    .filter__machine {

      .calendar-machine {
        @extend .common-select;
      }
    }
  }

  .calendar__tooltip {
    @extend .tooltip-actions--black-theme;

    .tooltip__content {

      .tooltip-name {
        font-size: 24px;
        font-weight: 600;
      }
      .tooltip-mid {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 4px;;
      }
    }
  }

  // 스케줄 추가 / 수정
  ::v-deep .add-schedule__popup {
    @extend .dialog--black-theme;
  }
  
  ::v-deep .delete-confirm {
    @extend .dialog--black-theme;
  }
</style>